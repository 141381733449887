// @ts-nocheck
import React, {  useEffect, useRef, useState } from 'react';
import './App.scss';
import { Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import Login from './pages';
import Home from './pages/search/home';
import Tnc from './pages/tnc';
import Success from './pages/success';
import CreatePo from './pages/buyer/createPo';
import BuyerSetting from './pages/buyer/buyerSetting';
import SellerSetting from './pages/seller/sellerSetting';
import OrderConfirmation from './pages/buyer/orderConfirmation';
import OrderConfirmationSeller from './pages/seller/orderConfirmation';
import AcceptOrder from './pages/seller/acceptOrder';
import Dispute from './pages/dispute/dispute';
import Order from './pages/dispute/order';
import {  
  CUSTOM_NOTIFICATION_ACTION,
  CUSTOM_NOTIFICATION_PRIORTY,
    chatUserRole,
    commomKeys,
    purchaseOrder,
    raygunKeys,
    routes,
    snackbarMessageContent,
    snackbarSeverityType,
    userRole
} from '../common';
import { Amplify, Auth } from 'aws-amplify';
import axios from 'axios';
import useCognitoUser from './hooks/useCognitoUser';
import { VERSION_NUMBER, dispatchRaygunError, getNumericVersion, getProductMapping, navigatePage, setNavigate, updatedAllProductsData } from './helper';
import Header from './pages/header';
import Loader from './Loader/Loader';
import rg4js from "raygun4js";
import ForgotPassword from './pages/forgotPassword';
import { useWindowEvent } from '@mantine/hooks';
import UpdatePopup from './component/UpdatePopup/updatePopup';
import MatPopup from './component/MatPopup/MatPopup';
import OnboardingWelcome from './pages/Onboarding/onboardingWelcome';
import OnboardingTnc from './pages/Onboarding/onboardingTnC';
import OnboardingDetails from './pages/Onboarding/onboardingDetails';
import OnboardingThankYou from './pages/Onboarding/onboardingThankyou';
import useGetForbiddenTooltips from './hooks/useGetForbiddenTooltips';
import ErrorBoundary from './component/Error/ErrorBoundary';
import ToastSnackbar from './component/Snackbar/ToastSnackbar';
import useSnackbarStore from './component/Snackbar/snackbarStore';
import { ReactComponent as CloseIcon } from '../../public/asset/Icon_Close.svg';
import useGetUserDiscountData from './hooks/useGetUserDiscountData';
import { useSellerOrderStore, useGlobalStore, createSocket, addAxiosInterceptor, removeAxiosInterceptor, initializeAxiosResponseInterceptor, useGetSecurityData, generateHashFromEncryptedData, excludeSecurityHashApiList, CustomSocketProp, setEnvironment, commonAppEventsofPusher, steg, getSocketConnection, resetConnection, RequestInterceptorParams, refereshImpersonatedUserToken, changePasswordConst, getChannelWindow, setChannelWindow, loginPageConst, forceLogoutConst} from '@bryzos/giss-ui-library';
import DialogPopup from './component/DialogPopup/DialogPopup';
import { ReferenceDataProduct } from './types/ReferenceDataProduct';
import useDialogStore from './component/DialogPopup/DialogStore';
import DialogBox from './component/DialogPopup/Dialog';
import { Chat } from './component/chat/Chat';
import { useImmer } from 'use-immer';
import Impersonate from './component/Impersonate/Impersonate';
import addErrorInterceptors from './utility/ErrorInterceptor';
import ChangePassword from './component/changePassword/changePassword';
import NoInternet from './component/NoInternet/NoInternet';
import { useHeightListener } from './hooks/useHeightListener';
import VideoLibrary from './pages/VideoLibrary/videoLibrary';
import useOurLogin from './hooks/useOurLogin';
import useGetReferenceData from './hooks/useGetReferenceData';
import useGetAllProducts from './hooks/useGetAllProducts';

rg4js("apiKey", import.meta.env.VITE_RAYGUN_KEY);
rg4js("enableCrashReporting", true);
rg4js('setVersion', VERSION_NUMBER);
rg4js('ignore', 'web_request_timing');
rg4js('options', {
  ignore3rdPartyErrors: true
});

if (!window.electron) {
    window.electron = {
        send: () => { },
        sendSync: () => { },
        receive: ()=>{},
        handleZoom: ()=>{},
        isWeb:true
    }
}

Amplify.configure({
  Auth: {
    region: import.meta.env.VITE_AWS_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env
      .VITE_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
    cookieStorage: {
      domain: import.meta.env.VITE_AWS_COGNITO_DOMAIN,
      path: import.meta.env.VITE_AWS_COGNITO_PATH,
      expires: Number(import.meta.env.VITE_AWS_COGNITO_EXPIRES),
      sameSite: import.meta.env.VITE_AWS_COGNITO_SAME_SITE,
      secure: Boolean(import.meta.env.VITE_AWS_COGNITO_SECURE),
    }
  },
  Analytics: { disabled: Boolean(import.meta.env.VITE_AWS_COGNITO_ANALYTICS_DISABLED) },
});

let currentUser = null;
// const maxRetries = 1;

const listChannelWindow = window.electron.sendSync({ channel: 'setChannelWindows' });
setChannelWindow(listChannelWindow);

let systemVersion;
let appVersion;

function App() {
    const [openMatPopup, setOpenMatPopup] = useState(false);
    const [disableInitialSecurityApiCall, setDisableInitialSecurityApiCall] = useState(false);
  const foregroundBackgroundActivityPayloadRef = useRef<CustomSocketProp>();

    const navigate = useNavigate();
    const location = useLocation();

    const ref = useHeightListener();

    const setSellerSettingsData = useGlobalStore(state => state.setSellerSettingsData);
    const { userData, setUserData, setSecurityHash, showErrorPopup, setShowErrorPopup, setAppVersion, resetCommonStore, sdk, setSdk, setShowChatIcon, noInternetAccessibility, setNoInternetAccessibility, setUserCredentials, userCredentials, setIsUserLoggedIn, setDecryptionEntity, setUserAccessToken, setCurrentAccessToken, currentAccessToken, setSystemVersion, originalLoggedInUserData, setOriginalLoggedInUserData, isImpersonatedUserLoggedIn, setIsImpersonatedUserLoggedIn, setHasLoginProcessCompleted,hasLoginProcessCompleted, startLoginProcess, setStartLoginProcess, apiFailureDueToNoInternet, setGlobalForceLogout } = useGlobalStore();
    const {viewedOrdersListForBadgeCount, setViewedOrdersListForBadgeCount,backdropOverlay, setBackdropOverlay, setSellerCompanyName, isUserLoggedIn, discountData, forceLogout, setForceLogout,setIsAppReadyToUseInState,isAppReadyToUseInState, autoLogin, setAutoLogin, passwordChanged, setPasswordChanged, setTriggerExitImpersonation, triggerExitImpersonation , setHideHeaderState } = useGlobalStore();
    const { showCommonDialog, resetDialogStore } = useDialogStore();
    const { resetOrderStore } = useSellerOrderStore();

    const {
      data: cognitoUser,
      // isLoading: isCognitoUserLoading,
      // refetch: refetchCognitoUser,
      // error: cognitoUserError,
    } = useCognitoUser(!window.electron?.isWeb ? isUserLoggedIn : true);

    const {
      refetch: getForbiddenTooltips,
      data: forbiddenTooltipsData,
    } = useGetForbiddenTooltips();

    const getUserDiscountData = useGetUserDiscountData();
    const ourLogin = useOurLogin();
    const getReferenceData = useGetReferenceData();
    const getAllProductsHook = useGetAllProducts();

    const {
      refetch: refetchGetSecurity
    } = useGetSecurityData(cognitoUser);

    const [socket, setSocket] = useState(null);
    // const socketContextValue =  { socket, setSocket };
    // const [connectStatus, setConnectStatus] = useState("Connect");

    // Store handlers
    // const poToBeAccepted = useGlobalStore(state => state.poToBeAccepted);
    const resetHeaderConfig = useGlobalStore(state => state.resetHeaderConfig);
    const showLoader = useGlobalStore(state => state.showLoader);
    const setShowLoader = useGlobalStore(state => state.setShowLoader);
    const disableBryzosNavigation = useGlobalStore(state => state.disableBryzosNavigation);
    const setDisableBryzosNavigation = useGlobalStore(state => state.setDisableBryzosNavigation);
    const channelWindow =  getChannelWindow()  ;
    const setForbiddenTooltips = useGlobalStore(state => state.setForbiddenTooltips);
    const setNavigationStateForNotification = useGlobalStore(state => state.setNavigationStateForNotification);
    const purchaseOrdersList = useSellerOrderStore(state => state.ordersCart);
    const {showToastSnackbar, resetSnackbarStore, setSnackbarOpen} = useSnackbarStore();
    const [pusherId, setPusherId] = useState(0);
    const [resumeApp, setResumeApp] = useState(false);
    const deviceId = useRef<string | null>(null);
    const [loginError, setLoginError] = useState();

    useWindowEvent('plugin_web_update_notice',  (e) => {
      const { version } = e.detail;
      // function will be called when update available
      // trigger notification
    });
  
    useEffect(() => {
      const _forbiddenTooltipsData = Array.isArray(forbiddenTooltipsData) ? forbiddenTooltipsData : [];
      setForbiddenTooltips(_forbiddenTooltipsData);
  }, [forbiddenTooltipsData]);

  useEffect(() => {
    if (userData?.data && discountData) {
      userData.data.disc_is_discounted = discountData.isDiscounted;
      userData.data.disc_discount_rate = discountData.discountRate;
      userData.data.disc_discount_pricing_column = discountData.discountPricingColumn;
      setUserData({...userData, productMapping: getProductMapping(userData.allProductsData, userData.data)});
    }
  }, [discountData]);

  useEffect(() => {
    setEnvironment(import.meta.env);
    const steg_fun = steg(document);
    steg_fun.decode("/asset/space_5.png", (_data) => {
      setDecryptionEntity(JSON.parse(_data));
    })
    addErrorInterceptors();
    setEnvironment(import.meta.env);
    if(!disableInitialSecurityApiCall){
      addSecurityHashInInterceptor(true);
    }
    if(window.electron?.isWeb) setStartLoginProcess(true);
    if(channelWindow?.electronVersion){
      appVersion = window.electron.sendSync({ channel: channelWindow.electronVersion });
      setAppVersion(appVersion);
    }
    initializeAxiosResponseInterceptor(setForceLogout, async(response)=>{
      await handleAxiosResponseData(response);
    });
    if(channelWindow?.systemVersion){
       systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion, data: null})
    }
    if(window.electron.handleZoom)
    window.electron.handleZoom();

    if(channelWindow?.handleURI){
      window.electron.receive(channelWindow.handleURI,(uri) => {
        const [protocol, path] = uri.split('//');      
        if(path){
            const isMail = path.split("/")[0] === "mail";
          if(isMail){
            openAppUsingLinkHandler(path);
          }else{
            notificationUriHandler(path);
          }
        }
      })
    }
    if(channelWindow?.markAsReadNotification){
      window.electron.receive(channelWindow.markAsReadNotification,(notificationList) => {
        notificationList = JSON.parse(notificationList);
        readNotificationAsRead(notificationList);
      });
    }
    if(channelWindow?.forceLogout) {
      window.electron.receive(channelWindow.forceLogout,() => {
        if(currentUser)
        setForceLogout(true);
      });
    }
    if(channelWindow?.discountPriceChanged) {
      window.electron.receive(channelWindow.discountPriceChanged,() => {
        if(currentUser){
          showOverlayToaster(snackbarMessageContent.discountPriceChanged, snackbarSeverityType.warning, [{name:commomKeys.refresh, handler: handleSnackbarClose}], null, true);
        }
      });
    }
    if(channelWindow?.productReferenceChanged){
      window.electron.receive(channelWindow.productReferenceChanged,() => {
        showOverlayToaster(snackbarMessageContent.productReferenceDataChanged, snackbarSeverityType.warning, [{name:commomKeys.tryAgain, handler: handleSnackbarClose}], null, true);
      });
    }
    if (channelWindow?.customNotification) {
      window.electron.receive(channelWindow.customNotification, (data) => {
        if (data?.notification) {
          showCustomNotification(data, true);
        }
      });
    }
    if(channelWindow?.getAccessToken){
      window.electron.receive(channelWindow.getAccessToken,(channelId) => {
        setPusherId(channelId);
      })
    }

    if(channelWindow?.resumeApp){
      window.electron.receive(channelWindow.resumeApp,()=>{
        setResumeApp(true);
      })
    }

    getDeviceId();
    setNavigate(navigate);
    return () => {
      socket?.disconnect();
      setSocket(null);
    };
  }, []);

  useEffect(()=>{
    if(resumeApp && currentUser){
      checkDiscountPricing();
      setResumeApp(false);
    }
  },[resumeApp, currentUser])

  useEffect(() => {
    let badgeCount = 0;
    if(viewedOrdersListForBadgeCount && purchaseOrdersList){
      const viewedOrderSet = new Set([...viewedOrdersListForBadgeCount]);
      badgeCount = purchaseOrdersList.reduce((count, order) => {
        if(!viewedOrderSet.has(order.buyer_po_number) && order.claimed_by === purchaseOrder.readyToClaim) count++;
        return count;
      }, 0);
    }
    if(channelWindow?.badgeCountHandler)
    window.electron.send({ channel: channelWindow.badgeCountHandler, data:{type: 'set', count:badgeCount }});
  }, [purchaseOrdersList, viewedOrdersListForBadgeCount]);


  //use this effect for cleanup after logging off
  useEffect(() => { 
    if(resetHeaderConfig){
      removeAxiosInterceptor();
      resetCommonStore();
      currentUser=null;
      foregroundBackgroundActivityPayloadRef.current= null;
      addSecurityHashInInterceptor(true);
      setForceLogout(false);
    }
  }, [resetHeaderConfig]);

  useEffect(() => {
    if(pusherId && channelWindow?.refreshPrivateChannel && cognitoUser){
      reconnectPusher()
    }
  },[pusherId])

  useEffect(()=>{
    if(showErrorPopup){
      showCommonDialog(null, commomKeys.errorContent, null, handleCloseErrorPopup, [{name: commomKeys.errorBtnTitle, action: handleCloseErrorPopup}])
    }
  },[showErrorPopup])

  const handleAxiosResponseData = async(response) => {
    const urlSplit = response.config.url.split("/");
      const pathName = urlSplit[urlSplit.length - 1];
      if((!excludeSecurityHashApiList.find((excludeSecurityHashApi) => pathName.startsWith(excludeSecurityHashApi)) && response?.data?.data === 'Success' && response.status === 200)){
        showCommonDialog(null, commomKeys.errorContent, null, handlePopupClose, [{name: commomKeys.errorBtnTitle, action: handlePopupClose}])
      }
  }

  const handlePopupClose = () => {
    if(channelWindow?.refreshApp)
    window.electron.send({ channel: channelWindow.refreshApp });
    resetDialogStore();
  };

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false)
    addSecurityHashInInterceptor(true);
    setShowLoader(false)
    resetDialogStore();
  }

  const checkDiscountPricing = async () => {
    try {
      const userDiscount = await getUserDiscountData.mutateAsync();

      if (!(currentUser.disc_is_discounted === userDiscount.is_discount &&  currentUser.disc_discount_rate === userDiscount.discount_rate && currentUser.disc_discount_pricing_column === userDiscount.discount_pricing_column)) {
        showOverlayToaster(snackbarMessageContent.discountPriceChanged, snackbarSeverityType.warning, [{name:commomKeys.refresh, handler: handleSnackbarClose}], null, true);
      }
      currentUser.disc_is_discounted = userDiscount.is_discount;
      currentUser.disc_discount_rate = userDiscount.discount_rate;
      currentUser.disc_discount_pricing_column = userDiscount.discount_pricing_column;
    } catch (error) {
      console.log(error)
    }

  }

  const reconnectPusher = async () => {
    try{
      let accessToken;
      if (isImpersonatedUserLoggedIn) accessToken = await refereshImpersonatedUserToken(originalLoggedInUserData)
      else {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        accessToken = user.signInUserSession.accessToken.jwtToken;
      }
      window.electron.send({ channel: channelWindow.refreshPrivateChannel, data: { channelId: pusherId, accessToken } });
    } catch(error) {
      console.log(error);
    } finally {
      setPusherId(null);
    }
  }

  const notificationUriHandler = (path) => {
      //const [protocol, path] = uri.split('//');     
      let [userId, notificationId, routePath, stateUrl] = path.split('/');
      if((+userId) === (+currentUser.id)){
        const currentPage = window.location.pathname;
        if(routePath)
        routePath = `/${routePath}`;
        const state = {};
        if(stateUrl){
          const stateUrlArray = stateUrl.split(',');
          for(const element of stateUrlArray){
            const [key, value] = element.split('=');
            state[key] = value;
          };
        }
        let isValidRoutePath = false;
        for(const routeKey in routes){
          if(!isValidRoutePath && routes[routeKey] === routePath) isValidRoutePath=true;
        }
        if(isValidRoutePath){
          setNavigationStateForNotification(state);
          navigatePage(location.pathname, {path:routePath}, { notificationClick: true, currentPage, nextPage: routePath });
        }
        // readNotificationAsRead([{notificationId}], false);
      }
      else{
        console.log(`Notication for User: ${userId} but found currentUser:${currentUser.id}`);
      }
  }

  const readNotificationAsRead = (notificationList) => {
    const deviceId = getDeviceId();

    const payload = {data:{notification_id: [], device_id: deviceId } };
    for(const notification of notificationList){
      payload.data.notification_id.push(notification.notificationId);
    }
    if(currentUser){
      axios.post(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/markAsRead', payload)
      .then(res => {
        if(channelWindow?.isMarkAsReadNotification)
        window.electron.send({ channel: channelWindow?.isMarkAsReadNotification, data:true })
      })
      .catch(err => {
        if(channelWindow?.isMarkAsReadNotification)
        window.electron.send({ channel: channelWindow?.isMarkAsReadNotification, data:false })
      });
    }
  }

  const openAppUsingLinkHandler = (path) => {
    if(path.indexOf("mail/create-po") >= 0){
      if(currentUser?.type === userRole.buyerUser)
      navigate("/create-po");

    }else if(path.indexOf("mail/buyer-setting") >= 0){
      if(currentUser?.type === userRole.buyerUser)
      navigate("/buyer-setting");
    }else{

    }
  }

  const handleSnackbarClose = async (event, reason) => {
    setSnackbarOpen(false);
    try {
        await axios.get(import.meta.env.VITE_API_SERVICE + '/reference-data/homepage');
        resetSnackbarStore();
        if(channelWindow?.refreshApp)
        window.electron.send({ channel: channelWindow.refreshApp });
    } catch (error) {
        setSnackbarOpen(true);
    }
  };

  const onlyCloseSnackbar = async () => {
    setSnackbarOpen(false);
    resetSnackbarStore();
    setBackdropOverlay(false);
  };

  const showOverlayToaster = (message, severity, buttons, closeHandler, showOverlay)=>{
    showToastSnackbar(message, severity, buttons, closeHandler);
    if(showOverlay)
    setBackdropOverlay(true);
  }

  const socketConnectionErrorHandler = (message) => {
    showOverlayToaster(message, snackbarSeverityType.alert, [{name:commomKeys.tryAgain, handler: handleSnackbarClose}], null, true);
  }
  const removeSocketDisconnectToaster = ()=>{
    resetSnackbarStore();
    setBackdropOverlay(false);
  }

  const onSocketConnectionError = (errorMsg)=>{
    dispatchRaygunError(
      new Error(errorMsg),
      [raygunKeys.socketInvalidToken.tag]
    );
    if(channelWindow?.refreshApp)
    window.electron.send({ channel: channelWindow.refreshApp });
    else
    setForceLogout(true);
  }

  async function createSocketConnection(userData, accessToken){
    const extraHeaders= {
      "gissToken": import.meta.env.VITE_WEB_SOCKET_GISS_TOKEN,
      "email": userData.email_id,
      "accessToken":  accessToken ?? currentAccessToken
    };

    if (!deviceId.current) {
      deviceId.current = await getDeviceId();
    }
    const data = await getOsAndLastLoginAppVersion();
    const object = {
      email_id: userData.email_id,
      os_version: data.os_version,
      last_login_app_version: data.last_login_app_version,
      device_id: deviceId.current,
      ui_version: import.meta.env.VITE_RENDERER_DEPLOY_VERSION,
    }
    foregroundBackgroundActivityPayloadRef.current = object;

    const socketProps = {
      userRole: userData.type,
      socketConnectionErrorHandler: socketConnectionErrorHandler,
      removeSocketDisconnectToaster:  removeSocketDisconnectToaster,
      onSocketConnectionError: onSocketConnectionError,
      ...object
    }
    const newSocket = await createSocket(import.meta.env.VITE_WEB_SOCKET_SERVER,extraHeaders, socketProps);
    // const newSocket = io(process.env.WEB_SOCKET_SERVER,{
    //   transports: ["polling", "websocket"],
    //     transportOptions: {
    //       polling: {
    //         extraHeaders,
    //       },
    //     },
    //   }
    // );
    
    // newSocket.on("connect", () => {
    //   console.log("connected to the server");
    // });

    // newSocket.on("disconnect", () => {
    //   console.log("connected to the server");
    // });
    setSocket(newSocket);
  }

  const getRefenceData = async (user, isImpersonated = false) => {
    const response = await getReferenceData.mutateAsync();
    const referenceData = response.data;
    await getAllProductsData(user, referenceData, isImpersonated);
  }

  const getAllProductsData = async (user, referenceData, isImpersonated) => {
    const response: ReferenceDataProduct[] = await getAllProductsHook.mutateAsync();
    const currentTandC = user.current_tnc_version;
    const acceptedTandC = user.accepted_terms_and_condition;
    const getAllProducts = updatedAllProductsData(response.data.data);
    setUserData({ "data": user, "referenceData": referenceData, "allProductsData" : getAllProducts, "productMapping": getProductMapping(getAllProducts, user) });

    if (location.pathname !== routes.newUpdate) {
      if (!isImpersonated && (currentTandC !== acceptedTandC || currentTandC === null || acceptedTandC === null)) {
        navigate(routes.TnCPage, {state : { isViewMode: false, navigateTo: routes.homePage }});
      }
      else if(channelWindow?.getLoginCredential && !isImpersonated && user.is_migrated_to_password <= 1){
        setShowLoader(false);
        navigate(routes.changePassword);
      } 
      else {
        setDisableBryzosNavigation(false);  
        if (!discountData) {
          navigate(routes.homePage);
        }
      }
    };
  }

  async function clickOnEnterSession(emailData, disableAccessToken = false, accessToken = null, userData=null) {
    setShowLoader(true);
    setHasLoginProcessCompleted(false);
    await addSecurityHashInInterceptor(disableAccessToken, accessToken, userData)
    const deviceId = getDeviceId();

    let payload = {
      data:  { "email_id": emailData, device_id: deviceId }
    }
    if(channelWindow?.systemVersion){
      payload.data.ui_version = import.meta.env.VITE_RENDERER_DEPLOY_VERSION;
      payload.data.os_version = systemVersion;
      payload.data.last_login_app_version = appVersion;
    }
    ourLogin.mutateAsync(payload).then(async (response) => {
      let userDetail = response.data.data;
      userDetail.isExistingUser = true;
      if(typeof userDetail === 'string'){
        clickOnEnterSession(emailData)
        setShowLoader(false);
        return;
      }
      setUserData({data: userDetail})
      setAppVersion(appVersion);
      setShowChatIcon(false);
      rg4js('setUser', {
        firstName: userDetail.first_name,
        lastName: userDetail.last_name,
        email: userDetail.email_id,
      });
      // if (!userDetail.chat_data?.unique_user_identifier) {
      //   const deadSimpleChatUser = await createDeadSimpleChatUser();
      //   if (!deadSimpleChatUser) {
      //     setShowChatIcon(false);
      //   } else {
      //     userDetail = (await axios.post(import.meta.env.VITE_API_SERVICE + '/user/login', payload))?.data?.data;
      //   }
      // }
      // if (userDetail.chat_data) {
      //   initiateDeadSimpleChat(userDetail.chat_data);
      // }

      const userExists = userDetail.id;
      if (userExists) {

        getForbiddenTooltips();
        // navigate to next page
        if(channelWindow?.windowStore)
        window.electron.send({ channel: channelWindow.windowStore, data: 'set'})
        const isImpersonated = accessToken ? true : false;
        await getRefenceData(userDetail, isImpersonated);
        setHasLoginProcessCompleted(true);
        setIsAppReadyToUseInState(true);
        removeSocketDisconnectToaster();
        await createSocketConnection(userDetail, accessToken);
        currentUser = userDetail;
        if(!isImpersonated){
          if(channelWindow?.pusher) window.electron.send({ channel: channelWindow.pusher, data:{user: userDetail, pusherId:userDetail.pusher_id, emailId: emailData, accessToken: currentAccessToken} });
          axios.get(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/getAllUnreadNotification ', payload)
          .then(res => {
              if(res.data.data){
                const _notificationList = res.data.data.notificationList;
                if (_notificationList && Array.isArray(_notificationList)) {
                  const filterNotification = _notificationList.filter(notificationData => {
                    const notificationObj = notificationData.notification;
                    if (notificationObj.event !== commonAppEventsofPusher.mobileEvents.capgoUpdate) {
                      return notificationData;
                    }
                  });
    
                  if (channelWindow?.showNotification && filterNotification.length !== 0)
                    window.electron.send({ channel: channelWindow.showNotification, data: filterNotification });
                }
                setUserJsonData(res.data.data);
              }
          })
          .catch(err => console.error(err));
          try {
            const deviceId = getDeviceId();
  
            const customNotificationList = (await axios.get(import.meta.env.VITE_API_NOTIFICATION_SERVICE + '/notification/getCustomNotification', { params: { device_id: deviceId } })).data?.data;
            if (customNotificationList?.length) {
              const notificationList = customNotificationList.map(notificationSchema => notificationSchema.notification);
              readNotificationAsRead(notificationList);
              const lastCustomNotification = customNotificationList[customNotificationList.length - 1];
              showCustomNotification(lastCustomNotification);
            }
          } catch (error) { }
        }
        setStartLoginProcess(false);
      } 
    })
    .catch(error => { 
        setShowLoader(false);
        currentUser = null;
        if(error?.response?.status === 400){
          setLoginError(loginPageConst.loginApprovalError);
          setForceLogout(true);
        }
    });

}

  const addSecurityHashInInterceptor = async (noAccessTokenRequire, currentAccessToken, userData) => {
    try{
      const res = await refetchGetSecurity();
      const securityHash = await generateHashFromEncryptedData(res.data, import.meta.env.VITE_SECRET_KEY);
      setSecurityHash(securityHash);
      setDisableInitialSecurityApiCall(true);
      axios.interceptors.request.clear();
      const params:RequestInterceptorParams = {
        impersonatedToken: currentAccessToken
      }
      addAxiosInterceptor(() => setForceLogout(true), (request) => {
        request.headers.security = securityHash;
        if(userData) request.headers["super-admin"] = userData.data.id
      }, noAccessTokenRequire, params);
    } catch (error) {
      console.log(error);
    }
    
  }

  const getOsAndLastLoginAppVersion = async () => {
    type responseType = { os_version: null | string; last_login_app_version: null | string; };
    let systemVersion = null;
    if (channelWindow?.systemVersion) {
    systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion });
    }

    const response: responseType = { os_version: systemVersion, last_login_app_version: appVersion };
    return response;
  }

  const getDeviceId = () => {
    if (!deviceId.current && channelWindow?.getDeviceId) {
      try {
        const id = window.electron.sendSync({ channel: channelWindow.getDeviceId });
        if (id && typeof id === 'string') {
          deviceId.current = id;
          return id;
        } else {
          deviceId.current = null;
          return null;
        }
      } catch (e) {
        deviceId.current = null;
        return null;
      }
    }
    return deviceId.current;
  };

const setUserJsonData = (jsonData) => {
  const badgeCountReadyToClaimList = jsonData.viewedOrdersListForBadgeCount
  if( badgeCountReadyToClaimList ) {
    const viewedOrderList = JSON.parse(badgeCountReadyToClaimList);
    setViewedOrdersListForBadgeCount(viewedOrderList ?? []);
  }
}

  const showCustomNotification = (data: any) => {
    if (data) {
      let action = data.notification.action;

      const message = data.notification.body;
      const severity = data.notification.priority;
      let buttons;
      if (action === CUSTOM_NOTIFICATION_ACTION[0]) {
        buttons = [{ name: CUSTOM_NOTIFICATION_ACTION[0], handler: handleSnackbarClose }];
      } else if (action === CUSTOM_NOTIFICATION_ACTION[1]) {
        buttons = [{ name: "", handler: onlyCloseSnackbar, icon: <CloseIcon /> }];
      }
      showOverlayToaster(message, severity, buttons, null, true);
    }
  }

useEffect(() => {
  if (!userData?.data && sdk) {
    sdk.logout();
  }
  if (userData?.data?.id && userRole.sellerUser === userData?.data?.type) {
    axios
      .get(import.meta.env.VITE_API_SERVICE + "/user/sellingPreference", {
        headers: {
          UserId: userData.data.id,
        },
      })
      .then((response) => {
        if (response.data && response.data.data) {
          if (
            typeof response.data.data === "object" &&
            "err_message" in response.data.data
          ) {
            setSellerSettingsData(null);
          } else {
            const sellerSettingData = response.data.data;
            setSellerCompanyName(sellerSettingData?.company_name ?? "");
            if(sellerSettingData?.company_name &&
              sellerSettingData?.company_address_line1 && 
              sellerSettingData?.company_address_city && 
              sellerSettingData?.company_address_state_id && 
              sellerSettingData?.company_address_zip && 
              sellerSettingData?.first_name && 
              sellerSettingData?.last_name && 
              sellerSettingData?.email_id && 
              sellerSettingData?.phone ){
              setSellerSettingsData(true);
            }else{
              setSellerSettingsData(null)
            }
          }
        } else {
          setSellerSettingsData(null);
        }
      })
      .catch(() => {
        setSellerSettingsData(null);
      });
  }
}, [userData?.data]);

  // useEffect(() => {
  //   if (!isCognitoUserLoading) {
  //     return;

  //     isLoggedIn.current = !!cognitoUser;
  //   }
  // }, [cognitoUser]);

useEffect(() => {
  if(startLoginProcess && !!cognitoUser && location.pathname !== routes.newUpdate){
    clickOnEnterSession(cognitoUser.attributes.email);
  }
}, [cognitoUser])

useEffect(()=>{
  if(window.electron?.isWeb){
    navigate(routes.loginPage);
  }
},[])

useEffect(()=>{
  if(!cognitoUser && location.pathname !== routes.newUpdate && appVersion){
    let showLoginPage = true;
    if(channelWindow?.windowStore)
      showLoginPage = window.electron.sendSync({ channel: channelWindow.windowStore, data: 'check'})
    if(!showLoginPage){
      navigate(routes.onboardingWelcome)
    }else{
      navigate(routes.loginPage)
    }
  }
},[cognitoUser, appVersion])

useEffect(()=>{
  if(autoLogin){
    refreshAndAutoLogin();
    setAutoLogin(false);
  }
},[autoLogin])

useEffect(()=>{
  if(passwordChanged){
    if(passwordChanged.device_id !== getDeviceId()){
      if(!isImpersonatedUserLoggedIn){
        if(userData.data.email_id === passwordChanged.email_id)  setGlobalForceLogout(routes.loginPage);
      }
      else {
        if(passwordChanged.email_id === originalLoggedInUserData.email_id){ 
          setGlobalForceLogout(routes.loginPage);
          setIsImpersonatedUserLoggedIn(false);
        }
        else if(userData.data.email_id === passwordChanged.email_id){
          showOverlayToaster(changePasswordConst.exitImpersonationContent, snackbarSeverityType.warning, [{name:commomKeys.errorBtnTitle, handler: exitImpersonation}], null, true);
        }
      }
    }
    setPasswordChanged(null);
  }
},[passwordChanged])

useEffect(()=>{
  if(forceLogout && isImpersonatedUserLoggedIn){
    showOverlayToaster(userData.data.email_id + forceLogoutConst.impersonateForceLogoutContent, snackbarSeverityType.warning, [{name:commomKeys.errorBtnTitle, handler: resetUser}], null, true);
    setForceLogout(false);
}
},[forceLogout])

useEffect(()=>{
  if(triggerExitImpersonation){
    exitImpersonation();
    setTriggerExitImpersonation(false);
    resetSnackbarStore();
  }
},[triggerExitImpersonation])

const exitImpersonation = async () => {
  setShowLoader(true);
  resetSnackbarStore()
  let payload = {
      data: {
          'email_id': userData.data.email_id
      }
  }
  if(channelWindow?.systemVersion){
      const systemVersion = window.electron.sendSync({ channel: channelWindow.systemVersion, data: null})
      payload.data.ui_version = import.meta.env.VITE_RENDERER_DEPLOY_VERSION;
      payload.data.os_version = systemVersion;
      payload.data.last_login_app_version = appVersion;
  }
  navigate(routes.loginPage);
  axios.post(import.meta.env.VITE_API_SERVICE + '/user/logout', payload)
  .then(() => {
    resetUser()
  })
    
}

const resetUser = () => {
  resetSnackbarStore()
  partialLogout();
  resetCommonStore();
  setShowLoader(true)
  if(channelWindow?.refreshApp){
    window.electron.send({ channel: channelWindow.refreshApp });
  }else{
    setShowLoader(false)
  }
}

const openUpdateLink = ()=>{
  window.open(import.meta.env.bryzosWebUrl);
  setTimeout(()=>{
    if(channelWindow?.close)
    window.electron.send({ channel: channelWindow.close })
  },0);
}

  const initiateDeadSimpleChat = async (data: any) => {
    // return new Promise(async (resolve) => {
      try {
        const sdk = new DSChatSDK(import.meta.env.VITE_DEAD_SIMPLE_CHAT_ROOM_ID, 'chat-frame', import.meta.env.VITE_DEAD_SIMPLE_CHAT_PUBLIC_KEY);
        await sdk.connect();
        if (data.is_moderator) {
          await sdk.joinRoom({ accessToken: data.access_token });
        } else {
          await sdk.joinRoom({ uniqueUserIdentifier: data.unique_user_identifier });
        }
        setSdk(sdk);
        setShowChatIcon(true);
      } catch (e) {
        setShowChatIcon(false);
        console.log(e);
      }
  };

  const createDeadSimpleChatUser = async () => {
    try {
      const response = (await axios.post(import.meta.env.VITE_API_SERVICE + '/user/createUser', null)).data?.data;
      return { unique_user_identifier: response };
    } catch (error) {
      return null;
    }
  }

  const loginImpersonatedUser = (accessToken, impersonatedUserData) => {
    if(accessToken && impersonatedUserData){
      partialLogout();
      setHideHeaderState(false)
      setCurrentAccessToken(accessToken)
      setUserAccessToken(currentAccessToken);
      setOriginalLoggedInUserData(userData.data);
      setIsImpersonatedUserLoggedIn(true);
      clickOnEnterSession(impersonatedUserData.email_id, true, accessToken, userData)
    }
  }

  const refreshAndAutoLogin = () => {
    if(channelWindow?.fetchOnRendererMount){
      partialLogout();
      setShowLoader(true);
      const systemData = window.electron.sendSync({ channel: channelWindow.fetchOnRendererMount });
      setUserCredentials(systemData.autoLoginCredential ?? changePasswordConst.noUserCredentialFound);
      navigate(routes.loginPage);
    }else{
      setShowLoader(false)
    }
  }

  const partialLogout = () => {
    setUserData({});
    setForbiddenTooltips([]);
    resetSnackbarStore();
    setBackdropOverlay(false)
    if(channelWindow?.badgeCountHandler)
    window.electron.send({ channel: channelWindow.badgeCountHandler, data: {type:'reset'}});
    getSocketConnection()?.disconnect();
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key !== "isSticky" && key !== "localStorageStickyItemKey") {
            sessionStorage.removeItem(key);
        }
    }
    resetConnection();
    resetOrderStore();
    setHasLoginProcessCompleted(false);
}

if((location.pathname !== routes.onboardingWelcome 
    && location.pathname !== routes.onboardingDetails 
    && location.pathname !== routes.onboardingTnc
    && location.pathname !== routes.onboardingThankYou
    && location.pathname !== routes.loginPage 
    && location.pathname !== routes.newUpdate 
    && location.pathname !== routes.forgotPassword
    && !isAppReadyToUseInState)){
  return <div ref={ref}>
  </div>
}
return ( 
    <div  ref={ref}>
      {backdropOverlay && 
          <div className='backdropOverlay' />
      }
      <div className={`widgetCanvas blurBg  ${window.electron.isWeb ? 'webBackground' : ''} ${backdropOverlay ? 'isbackdropOverlay' : ''} ${(noInternetAccessibility || apiFailureDueToNoInternet)  ? 'noInternetWindow' : ''}` }>
      <NoInternet />
      <ErrorBoundary>
        {location.pathname !== routes.onboardingWelcome && 
          <Header disableBryzosNavigation={disableBryzosNavigation} forceLogout={forceLogout} setForceLogout={setForceLogout} appVersion={appVersion} partialLogout={partialLogout}  resetImpersonate={clickOnEnterSession}/>
        }
        <div className='bgImg'>
          {
            showLoader &&
            <div className={`loaderContent ${hasLoginProcessCompleted ? 'loaderMain' : 'loginLoader'}`}>
              <Loader />
            </div>
          }
          <div className={` ${location.pathname !== routes.loginPage ? 'headerPanel commonHeader' : `loginBody ${window.electron.isWeb ? 'webLoginHeight' : ''}`} ${showLoader && 'loaderRunning'}`}>
            <Routes>
                <Route exact path={routes.onboardingWelcome} element={<OnboardingWelcome />} />
                <Route path={routes.onboardingDetails} element={<OnboardingDetails />} />
                <Route path={routes.onboardingTnc} element={<OnboardingTnc />} />
                <Route path={routes.onboardingThankYou} element={<OnboardingThankYou />} />
                <Route path={routes.loginPage} element={<Login loginError={loginError} setLoginError={setLoginError}/>} />
                <Route exact path={routes.forgotPassword} element={<ForgotPassword />} />
                <Route exact path={routes.changePassword} element={<ChangePassword getDeviceId={getDeviceId}/>} />
                <Route path={routes.homePage} element={<Home />} />
                <Route path={routes.TnCPage} element={<Tnc />} />
                <Route path={routes.successPage} element={<Success />} />
                <Route path={routes.buyerSettingPage} element={<BuyerSetting appVersion={appVersion} getDeviceId={getDeviceId}/>} />
                <Route path={routes.sellerSettingPage} element={<SellerSetting appVersion={appVersion} getDeviceId={getDeviceId}/>} />
                <Route path={routes.createPoPage} element={<CreatePo />} />
                <Route path={routes.videoLibrary} element={<VideoLibrary />} />
                <Route path={routes.orderConfirmationPage} element={<OrderConfirmation />} />
                <Route path={routes.acceptOrderPage} element={<AcceptOrder />} />
                <Route path={routes.disputePage} element={<Dispute />} />
                <Route path={routes.orderPage} element={<Order />} />
                <Route path={routes.newUpdate} element={<UpdatePopup />} />
                <Route path={routes.chat} element={<Chat />} />
                <Route path={routes.impersonateList} element={<Impersonate loginImpersonatedUser={loginImpersonatedUser} />} />
              </Routes>
          </div>
        </div>
        <MatPopup open={openMatPopup}
          popupClass={{
            root: 'UpdateButton',
            paper: 'dialogContent'
          }}>
          <div className='excitingNews'>
            <label>Exciting News! </label>
            <p>We've added auto-updates to the app. Please uninstall your current app, then download the latest version from <span onClick={openUpdateLink}>here</span>. After this one-time manual update, all future updates will be automatic. Thanks for your support!</p>
          </div>
        </MatPopup>
        <DialogBox />
      </ErrorBoundary>
    </div>
    </div>
);
}
  
export default App;