import React, { useEffect } from "react";
import { ReactComponent as NoInternetIcon } from '../../assets/images/No_Internet_Connection.svg';
import styles from './NoInternet.module.scss';
import { getChannelWindow, reconnectToSocket, useGlobalStore, useSellerOrderStore } from "@bryzos/giss-ui-library";
import clsx from "clsx";
import { ReactComponent as CloseIcon } from '../../assets/images/Icon_Close.svg';
import { ReactComponent as MinimizeAppIcon } from '../../assets/images/Minimize_App.svg';

type Props = {
    open: boolean;
}

const NoInternet: React.FC<Props> = () => {
    const { noInternetAccessibility, apiFailureDueToNoInternet }: any = useGlobalStore();
    const {socketDisconnectAfterMaxRetries, setSocketDisconnectAfterMaxRetries}: any = useSellerOrderStore();
    const channelWindow:any =  getChannelWindow();

    useEffect(()=>{
        if((apiFailureDueToNoInternet || noInternetAccessibility) && channelWindow?.updateHeight) {
            (window as any).electron.send({ channel: channelWindow.updateHeight, data:800 })
        }
    },[noInternetAccessibility, apiFailureDueToNoInternet])
    
    if(!(apiFailureDueToNoInternet || noInternetAccessibility))
    return<></>

    const minimizeBtnClick = () => {
        if(channelWindow?.minimize){
            window.electron.send({ channel: channelWindow.minimize })
        }
    }

    const closeBtnClick = () => {
        if(channelWindow?.close){
            window.electron.send({ channel: channelWindow.close })
        }
    }

    const retryConnection = () => {
        reconnectToSocket();
    }

    const refreshApp = () => {
        if(channelWindow?.refreshApp)
            window.electron.send({ channel: channelWindow.refreshApp });
    }

    return (
        <div className={clsx(styles.container, styles.flex_center)}>
            <div className={styles.no_internet_btn}>
            <span onClick={minimizeBtnClick}><MinimizeAppIcon className='minimizeAppIcon' /></span>
                <span onClick={closeBtnClick}><CloseIcon /></span>
            </div>
            <div className={clsx(styles.main_content, styles.flex_center)}>
                <div className={styles.flex_center}>
                    <NoInternetIcon/>
                </div>
                <p className={styles.header}>No Internet Connection</p>
                <p className={styles.content}>Please check your internet connection</p>
                {apiFailureDueToNoInternet && <button className={styles.try_again_btn} onClick={refreshApp}>Refresh App</button> }
                {(!apiFailureDueToNoInternet && socketDisconnectAfterMaxRetries) && <button className={styles.try_again_btn} onClick={retryConnection}>Reconnect</button> }
            </div>
        </div>
    );
};

export default NoInternet;