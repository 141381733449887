// @ts-nocheck
import {  useEffect, useState } from "react";
import styles from "../createPo.module.scss";
import { Controller } from "react-hook-form";
import { Autocomplete, ClickAwayListener, Fade, Tooltip } from "@mui/material";
import { CustomMenu } from "../CustomMenu";
import clsx from "clsx";
import { ReactComponent as RemoveLineIcon } from "../../../assets/images/Remove-line.svg";
import { ReactComponent as RemoveLineHoverIcon } from "../../../assets/images/Remove-line-hover.svg";
import { ReactComponent as QuestionIcon } from '../../../assets/images/setting-question.svg';
import { ReactComponent as QuestionHoverIcon } from '../../../assets/images/question-white-hover.svg';
import { useDebouncedValue } from "@mantine/hooks";
import axios from "axios";
import { CommonTooltip } from "../../../component/Tooltip/tooltip";
import useSaveUserActivity from "../../../hooks/useSaveUserActivity";
import { v4 as uuidv4 } from 'uuid';
import { formatDollarPerUnit, searchProducts, getValidSearchData, useGlobalStore, emojiRemoverRegex, MinSearchDataLen, formatToTwoDecimalPlaces  } from '@bryzos/giss-ui-library';

const SAME_TAG_ERROR_MESSAGE =
  "Same part? <br />You have applied this part number to another product already.";

const CreatePoTile = ({
  index,
  register,
  fields,
  updateValue,
  products,
  control,
  setValue,
  watch,
  errors,
  getValues,
  userPartData,
  sessionId,
  selectedProduct,
  searchStringData,
  setSearchString,
  setLineSessionId,
  lineSessionId,
  handleCreatePOSearch,
  setHandleSubmitValidation,
  handleSalesTax,
  apiCallInProgress,
  updateLineProduct,
  removeLineItem,
  pricePerUnitChangeHandler,
  quantitySizeValidator,
  saveUserLineActivity
}) => {
  const { userData } = useGlobalStore();
  const [descriptionInput, setDescriptionInput] = useState("");
  const [sameTagErrorMsg, setSameTagErrorMsg] = useState(null);
  const [openDescription, setOpenDescription] = useState(false);
  const [isQtyInEditMode, setIsQtyInEditMode] = useState(true);
  const [showDomesticMaterialOnly, seShowDomesticMaterialOnly] = useState(false);
  const [enableRejectSearchAnalytic, setEnableRejectSearchAnalytic]= useState(false);

  const { mutate: logUserActivity, } = useSaveUserActivity();

  useEffect(() => {
    setValue(`cart_items.${index}.line_session_id`, uuidv4());
    if (index > 0) {
      saveUserActivity();
    }
    qtyEditModeCloseHandler();
  }, []);

  useEffect(() => {
    const description = getValues(`cart_items.${index}.descriptionObj`);


    if (userData.referenceData) {
      setValue(
        `cart_items.${index}.domesticMaterialOnly`,
        false
      );
    }
    if (description) {
      if (!description.domestic_material_only) {
        seShowDomesticMaterialOnly(false);
        setValue(`cart_items.${index}.domesticMaterialOnly`, null);
      } else {
        seShowDomesticMaterialOnly(true);
      }
    } else {
      seShowDomesticMaterialOnly(true);
    }
  }, [userData?.referenceData, watch(`cart_items.${index}.descriptionObj`)]);

  useEffect(() => {
    const product_tag = getValues(`cart_items.${index}.product_tag`);
    const description = getValues(`cart_items.${index}.descriptionObj`);

    if (description) {
      setValue(`cart_items.${index}.description`, description.UI_Description);
      setValue(`cart_items.${index}.shape`, description.Key2);
      setValue(`cart_items.${index}.product_id`, description.Product_ID);
      setValue(`cart_items.${index}.reference_product_id`, description.id);
      if (!description.domestic_material_only) {
        seShowDomesticMaterialOnly(false);
        setValue(`cart_items.${index}.domesticMaterialOnly`, null);
      } else {
        seShowDomesticMaterialOnly(true);
      }
    } else {
      seShowDomesticMaterialOnly(true);
    }

    if (description) {
      if (userPartData && Object.keys(userPartData)?.length) {
        let isMappingExist = false;
        const i = Object.keys(userPartData).findIndex((key)=>{
          if(userPartData[key] === product_tag){
            isMappingExist = true;
            if(+key === description.Product_ID){
               return true;
            }
          }
        })
        setSameTagErrorMsg(i > -1 ? null : SAME_TAG_ERROR_MESSAGE);
        if (!isMappingExist) {
          setSameTagErrorMsg(null);
        }
      }
    } else {
      setSameTagErrorMsg(null);
      setValue(`cart_items.${index}.product_tag`, "");
    }
  }, [
    watch(`cart_items.${index}.product_tag`),
    watch(`cart_items.${index}.descriptionObj`),
  ]);

  useEffect(() => {
    if (!getValues(`cart_items.${index}.descriptionObj`) || errors?.cart_items?.[index]?.qty) {
      setIsQtyInEditMode(true);
    }
  }, [
    getValues(`cart_items.${index}.descriptionObj`),
    errors?.cart_items?.[index]?.qty,
  ]);


useEffect(() => {
  if(selectedProduct && selectedProduct.UI_Description === descriptionInput && lineSessionId === getValues(`cart_items.${index}.line_session_id`)){
    setEnableRejectSearchAnalytic(false)
    handleCreatePOSearch(searchStringData, 'Accept', getValues(`cart_items.${index}.line_session_id`))
  }
},[selectedProduct])

useEffect(() => {
  if((!selectedProduct && descriptionInput.length >= MinSearchDataLen) || (descriptionInput.length >= MinSearchDataLen)){
    setSearchString(descriptionInput)
    setLineSessionId(getValues(`cart_items.${index}.line_session_id`))
  }
  if(descriptionInput.length === 1 ){
    setEnableRejectSearchAnalytic(true)
  }
  if(descriptionInput.length === 0 && searchStringData.length !== 0 && searchStringData.length >= MinSearchDataLen && enableRejectSearchAnalytic){
    handleCreatePOSearch(searchStringData, 'Reject', getValues(`cart_items.${index}.line_session_id`))
    setEnableRejectSearchAnalytic(false)
  }
},[descriptionInput])

  function display(data) {
    const lines = data.UI_Description.split("\n");
    const firstLine = lines[0];
    const restLines = lines.slice(1);

    return (
      <>
        <p className="liHead">{firstLine}</p>
        {restLines.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </>
    );
  }

  const qtyEditModeCloseHandler = () => {
    if (
      getValues(`cart_items.${index}.descriptionObj`) &&
      getValues(`cart_items.${index}.qty`) &&
      !errors?.cart_items?.[index]?.qty
    ) {
      setIsQtyInEditMode(false);
    } else {
      setIsQtyInEditMode(true);
    }
  };

  const quantityChangeHandler = (event:any) => {
    quantitySizeValidator(event,index);
    updateValue(index);
  }

  const dollerPerUmFormatter = (umVal) => {
    const price_unit = getValues(`cart_items.${index}.price_unit`);
    return formatDollarPerUnit(price_unit,umVal,index);
  }

  const saveUserActivity = (isRemovingLine = false) => {
    saveUserLineActivity(sessionId, isRemovingLine, index);
  }

  return (
    <>
    <tr>
      <td className={styles.removeLine}>
        {fields.length > 1 && index > 0 && (
           <CommonTooltip
           title={'Click the - button to remove any line items from your order.'}
           tooltiplabel={<>
                <button
                  onClick={() => {
                      saveUserActivity(true);
                      removeLineItem(index);
                    }}
                  >
                    <RemoveLineIcon className={styles.removeLineIcon} />
                    <RemoveLineHoverIcon className={styles.removeLineHoverIcon} />
          </button>
           </>
              
           }
           placement={'bottom-start'}
           classes={{
               popper: 'tooltipPopper',
               tooltip: 'tooltipMain tooltipSearch tooltipAddRow tooltipAddRow tooltipRemove',
               arrow: 'tooltipArrow'
           }}
           localStorageKey="removeLineTooltip"
       />
         
        )}
        <div className={styles.prodId}>{index + 1}</div>
      </td>
      <td>
        <>
          <Controller
            name={register(`cart_items.${index}.descriptionObj`).name}
            control={control}
            render={({ field: { ...rest } }) => (
              <Autocomplete
                id={`combo-box-demo${index}`}
                disabled={apiCallInProgress}
                open={descriptionInput.length > 1 && openDescription}
                onOpen={() => setOpenDescription(true)}
                onClose={(e) => {
                  if(e.keyCode === 27){
                    setDescriptionInput('')
                  }
                  setOpenDescription(false)
                }}
                options={products?.length ? products : []}
                inputValue={descriptionInput}
                onInputChange={(event, value) => {
                  value = value.replace(emojiRemoverRegex, '');
                  setDescriptionInput(value);
                }}
                disablePortal={true}
                classes={{
                  root: styles.autoCompleteDesc,
                  popper: styles.autocompleteDescPanel,
                  paper: styles.autocompleteDescInnerPanel,
                  listbox: styles.listAutoComletePanel,
                }}
                filterOptions={(options, state) => {
                  return (
                    state.inputValue.trim().length > 1 &&
                    searchProducts(
                      options,
                      getValidSearchData(state.inputValue),
                      state.inputValue,
                      userPartData,
                      true
                    )
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.UI_Description === value.UI_Description
                }
                getOptionLabel={(item) => {
                  return item.UI_Description ? item.UI_Description : "";
                }}
                renderInput={(params) => (
                  <div className={styles.lineNone} ref={params.InputProps.ref}>
                    {descriptionInput.length === 0 &&
                      <Tooltip
                        title={'Add products to your order by typing them into this box'}
                        arrow
                        disableInteractive
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement={'top'}
                        classes={{
                          popper: 'tooltipPopper',
                          tooltip: 'tooltipMain tooltipDesc',
                          arrow: 'tooltipArrow'
                        }}
                      >
                        <span className={styles.questionIconDesc}>
                          <QuestionIcon className={styles.questionIcon1} />
                          <QuestionHoverIcon className={styles.questionIcon2} />
                        </span>
                      </Tooltip>
                    }

                                     
                    <textarea
                      type="text"
                      {...params.inputProps}
                      placeholder="Click to Enter Line"
                      className={clsx(styles.poDescription)}
                    />
                  </div>
                )}
                {...rest}
                onChange={(event, item) => {
                  updateLineProduct(index, item);
                  setIsQtyInEditMode(true);
                  rest.onChange(item);
                }}
                onBlur={(e) => {
                  rest.onBlur(e);
                  saveUserActivity();
                }}
                renderOption={(props, option) => {
                  return (
                    <span key={option.id} {...props}>
                      {display(option)}
                    </span>
                  );
                }}
              />
            )}
          />
          <Controller
            control={control}
            name={register(`cart_items.${index}.product_tag`).name}
            render={({ field: { value, onBlur, onChange, ...field }, fieldState: { error } }) => (
              <Tooltip
                title={
                  sameTagErrorMsg && (
                    <span
                      dangerouslySetInnerHTML={{ __html: sameTagErrorMsg }}
                    ></span>
                  )
                }
                arrow
                placement={"bottom-end"}
                disableInteractive
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 200 }}
                classes={{
                  tooltip: "partNumberTooltip",
                }}
              >
                <div className={styles.partNumberFiled}>
                  <input
                    value={value ?? ""}
                    disabled={apiCallInProgress}
                    onKeyUp={(e) => {
                      setValue(
                        `cart_items.${index}.product_tag`,
                        e.target.value
                      );
                    }}
                    placeholder="Tag with your part #"
                    className={clsx({ [styles.errorInput]: error?.message })}
                    {...field}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                      onChange(e);
                    }}
                    onBlur={(e) => {
                      onBlur(e);
                      saveUserActivity();
                    }}
                  />
                </div>
              </Tooltip>
            )}
          />

        </>
      </td>
      <td>
        <div className={styles.poQty}>
          <div>
            {isQtyInEditMode ? (
              <ClickAwayListener  onClickAway={qtyEditModeCloseHandler}>
                <Tooltip
                  title={
                    watch(`cart_items.${index}.qty`) !== null &&
                    errors?.cart_items?.[index]?.qty?.message
                  }
                  arrow
                  placement={"top"}
                  disableInteractive
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 200 }}
                  classes={{
                    tooltip: "inputQtyTooltip",
                  }}
                >
                  <input
                    type="text"
                    disabled={apiCallInProgress}
                    className={
                      errors?.cart_items?.[index]?.qty?.message &&
                      watch(`cart_items.${index}.qty`) !== null &&
                      styles.errorInput
                    }
                    {...register(`cart_items.${index}.qty`)}
                      value={watch(`cart_items.${index}.qty`) ?? ""}
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                      register(`cart_items.${index}.qty`).onChange(e);
                      quantityChangeHandler(e);
                    }}
                    onBlur={(e) => {
                      register(`cart_items.${index}.qty`).onBlur(e);
                      qtyEditModeCloseHandler();
                      saveUserActivity();
                    }}
                  />
                </Tooltip>
              </ClickAwayListener>
            ) : (
              <p
                onClick={() => {
                  setIsQtyInEditMode(true);
                }}
              >
                {formatToTwoDecimalPlaces(
                  watch(`cart_items.${index}.qty`)
                )}
              </p>
            )}
          </div>
          <span className={styles.selectUom}>
            <CustomMenu
              name={register(`cart_items.${index}.qty_unit`).name}
              control={control}
              disabled={apiCallInProgress}
              onChange={() => {
                updateValue(index);
                saveUserActivity();
              }}
              items={
                getValues(`cart_items.${index}.qty_um`)?.map((x) => ({ title: x, value: x })) ?? []
              }
              className={styles.uomDrodown}
              MenuProps={{
                classes: {
                  paper: styles.selectUomPaper,
                },
              }}
              placeholder={"Ft"}
            />
          </span>
        </div>
      </td>
      <td>
        <div className={styles.poPerUm}>
          <div>
            {dollerPerUmFormatter(
              watch(`cart_items.${index}.price`) ?? 0
            )}
          </div>
          <span className={styles.selectUom1}>
            <CustomMenu
              name={register(`cart_items.${index}.price_unit`).name}
              control={control}
              disabled={apiCallInProgress}
              onChange={() => {
                pricePerUnitChangeHandler(index);
                saveUserActivity();
                }}
              items={
                getValues(`cart_items.${index}.price_um`)?.map((x) => ({ title: x, value: x })) ?? []
              }
              className={styles.uomDrodown}
              MenuProps={{
                classes: {
                  paper: styles.selectUomPaper,
                },
              }}
              placeholder={"Ft"}
            />
          </span>
        </div>
      </td>
      <td>
        <div>
          {formatToTwoDecimalPlaces(
            watch(`cart_items.${index}.extended`) ?? 0
          )}
        </div>
      </td>
    </tr>
    <tr>
      <td></td>
      <td colSpan={4}>
        <div className={styles.domesticMaterialCheckbox}>
          <div className={styles.div50}>
            {showDomesticMaterialOnly && (
              <label className={clsx(styles.lblCheckbox, "containerChk")}>
                <input
                  type="checkbox"
                  checked={watch(
                    `cart_items.${index}.domesticMaterialOnly`
                  )}
                  {...register(
                    `cart_items.${index}.domesticMaterialOnly`
                  )}
                  onChange={(e) => {
                    register(`cart_items.${index}.domesticMaterialOnly`).onChange(e);
                    saveUserActivity();
                  }}
                />
                <span className={styles.domesticMaterialTex}>
                  Domestic (USA) Material Only
                </span>
                <span className={clsx(styles.checkmark, "checkmark")} />
              </label>
            )}
          </div>
        </div>
      </td>

    </tr>
    </>
  );
};

export default CreatePoTile;
