//@ts-nocheck
import { PDF_TEMPLATE_EXPORT, RecevingHoursFrom, RecevingHoursTo, commomKeys, httpsOrigin, pdfMakeData, raygunKeys, refererSuffix, userRole } from '../../../common';
import { dispatchRaygunError, format4DigitAmount, formatPhoneNumber, formatPhoneNumberRemovingCountryCode, formatToTwoDecimalPlaces, getAccessToken, getUnitPostScript, pdfGenreationError } from '../../helper';
import { ReactComponent as ExportPdfIcon } from '../../assets/images/Export-PO.svg';
import DialogPopup from 'src/renderer/component/DialogPopup/DialogPopup';
import { useEffect, useState , useRef} from 'react';
import { compareVersions, getChannelWindow, refereshImpersonatedUserToken, useGlobalStore } from '@bryzos/giss-ui-library';
import { compare } from 'compare-versions';
import { calculateBuyerTotalOrderWeight, calculateSellerTotalOrderWeight } from '../../utility/pdfUtils';


function PdfMakePage({ getExportPoData, buyingPreferenceData, sellerData, disabled, disableOnclick,getCartItems }) {
    const channelWindow =  getChannelWindow();
    const [isExportPdfGenerate, setIdExportPdfGenreate] = useState('');
    const { setShowLoader, securityHash, appVersion, setAppVersion, isImpersonatedUserLoggedIn, originalLoggedInUserData} = useGlobalStore();
    const appVersionRef = useRef(appVersion);

    const calculateLineWeight = (data) => {
        let lineWeight = 0;
        const updatedUnit = getUnitPostScript(data.qty_unit);
        try {
            if (data?.descriptionObj?.buyerPricing_LB) {
                const buyerPricePerUnit = data.descriptionObj[`buyerPricing_${updatedUnit}`]?.replace(/[\$,]/g, '');
                if (buyerPricePerUnit) {
                    const actualBuyingPricePerUnit = parseFloat(buyerPricePerUnit);
                    const actualBuyerExtended = parseFloat(actualBuyingPricePerUnit * data.qty);
                    lineWeight = actualBuyerExtended / data.descriptionObj.buyerPricing_LB.replace(/[\$,]/g, "");
                }
            } else {
                lineWeight = data.extendedValue / data?.descriptionObj?.Buyer_Pricing_LB.replace(/[\$,]/g, "");
            }
            totalOrderWeights = +(formatToTwoDecimalPlaces(totalOrderWeights)) + +(formatToTwoDecimalPlaces(lineWeight).replace(/[\$,]/g, ""));
        } catch (error) {
            const raygunErrorData = [data.descriptionObj[`buyerPricing_${updatedUnit}`], data.descriptionObj.buyerPricing_LB, data?.descriptionObj?.Buyer_Pricing_LB]
            dispatchRaygunError(
                Error(JSON.stringify(raygunErrorData)),
                raygunKeys.buyerPriceNull.tag
            );
        } finally {
            return formatToTwoDecimalPlaces(lineWeight ?? 0);
        }
    }

    const formatReceivingHours = (recHrs) => {
        if (recHrs.from === "closed") {
            return { day: recHrs.display_name, timing: "Closed" };
        }
        const fromTime = RecevingHoursFrom.find(obj => obj.value === +recHrs.from)?.title || 'N/A';
        const toTime = RecevingHoursTo.find(obj => obj.value === +recHrs.to)?.title || 'N/A';
        return { day: recHrs.display_name, timing: `${fromTime} to ${toTime}` };
    }
    async function openPdf() {
        setShowLoader(true);
        const pdfData = {};
        const buyer = buyingPreferenceData?.userType === userRole.buyerUser;
        const seller = sellerData?.userType === userRole.sellerUser;
        let createPoData
        if(buyer && getExportPoData){
            createPoData = getExportPoData();
        }
        if(buyer && !buyingPreferenceData?.phone){
            setIdExportPdfGenreate("No buyer settings found. Please add buyer settings.")
            setShowLoader(false);
            return;
        }
        const date = new Date();
        const orderedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear().toString().slice(2);
        if (createPoData) {
            createPoData.stateCode = buyingPreferenceData.stateRef.find(stateDetail => stateDetail.id == createPoData.shipping_details.state_id)?.code
        }
        const data = buyer ? createPoData : sellerData;
        const shippingDetails = buyer ? `${buyingPreferenceData.company_name},\n ${data.shipping_details.line1},\n ${data.shipping_details.city}, ${data.stateCode} ${data.shipping_details.zip}` 
                                : `${data.city}, ${data.stateCode} ${data.zip}`
        const contactInfo = buyer ? `${buyingPreferenceData.first_name} ${buyingPreferenceData.last_name}\n${buyingPreferenceData.email_id}\n${formatPhoneNumber(formatPhoneNumberRemovingCountryCode(buyingPreferenceData.phone)) ?? ""}` 
                                : '';
        pdfData.data = {}
        pdfData.data.details = {}
        pdfData.data.items = [];
        pdfData.data.userType = buyer ? "buyer" : "seller";
        pdfData.data.details.companyName = buyer ? buyingPreferenceData.company_name : data.sellerCompanyName;
        pdfData.data.details.poNo = buyer ? data.internal_po_number : ''
        pdfData.data.details.version = buyer ? 'SAVED DRAFT' : 'AVAILABLE TO CLAIM';
        pdfData.data.details.draftDate = buyer ? orderedDate : data.createDateFormat;
        pdfData.data.details.deliveryDueDate = buyer ? data.delivery_date : data.deliveryDate;
        pdfData.data.details.freightTerm = buyer ? `FOB Destination (Delivered)` : `FOB Destination (Delivered)`;
        pdfData.data.details.shipTo = shippingDetails;
        pdfData.data.details.contact_info = contactInfo;
        pdfData.data.items = getCartItems()
        pdfData.data.receivingHours = buyer ? data.recevingHours.map(formatReceivingHours) : '';
        const totalOrderWeightValue = buyer ? calculateBuyerTotalOrderWeight(pdfData.data.items) : calculateSellerTotalOrderWeight(pdfData.data.items)
        pdfData.data.details.totalOrderWeight = (((totalOrderWeightValue) - Math.floor(totalOrderWeightValue)).toFixed(2) === "0.00") ? totalOrderWeightValue : formatToTwoDecimalPlaces(totalOrderWeightValue);
        pdfData.data.details.materialTotal = buyer ? data.price : data.seller_po_price;
        pdfData.data.details.salesTax = buyer ? data.sales_tax : data.seller_sales_tax;
        pdfData.data.details.totalPurchase = buyer ? data.totalPurchase : data.totalOrderValue
        pdfData.data.details.depositAmount = data.depositAmount
        pdfData.data.details.selectedOptionPayment = data.selectedOptionPayment
        pdfData.file_name = pdfMakeData.pdfName;
        pdfData.pdfName = pdfMakeData.pdfName;

        try {
            if (channelWindow?.fetchPdf) {
                if (isImpersonatedUserLoggedIn) {
                    const token = await refereshImpersonatedUserToken(originalLoggedInUserData);
                    createPdf(pdfData, token);
                } else {
                    const token = await getAccessToken();
                    createPdf(pdfData, token);
                }
            } 
            else if (channelWindow?.generatePdf) window.electron.send({ channel: channelWindow.generatePdf, data: JSON.stringify(pdfData) })
            else setShowLoader(false);
        } catch (error) {
            console.log(error)
            setShowLoader(false);
        }
    }

    const createPdf = (pdfData, token) => {
        pdfData.url = import.meta.env.VITE_API_SERVICE + '/user/createPdf';
        pdfData.file_type = PDF_TEMPLATE_EXPORT;
        if(compareVersions(appVersion,import.meta.env.VITE_SECURITY_MIDDLEWARE_APP_VERSION) >= 0){
            pdfData.headersObj = { 
                'AccessToken': token,
                'security': securityHash,
                'Origin': httpsOrigin + import.meta.env.VITE_AWS_COGNITO_DOMAIN,
                'Referer': httpsOrigin + import.meta.env.VITE_AWS_COGNITO_DOMAIN+ refererSuffix,
                'User-Agent': import.meta.env.VITE_PDF_USER_AGENT
            };
        }else{
            pdfData.accessToken = token;
        }
        console.log("first", pdfData,appVersion,import.meta.env.VITE_SECURITY_MIDDLEWARE_APP_VERSION);
        window.electron.send({ channel: channelWindow.fetchPdf, data: JSON.stringify(pdfData) })
    }

    useEffect(()=>{
        if (!appVersionRef.current && channelWindow?.electronVersion) {
            appVersionRef.current = window.electron.sendSync({ channel: channelWindow.electronVersion });
            setAppVersion(appVersionRef.current);
        }

        if (channelWindow?.fetchPdfResult) {
            window.electron.receive(channelWindow.fetchPdfResult, (pdfResult) => {
                if (pdfResult.code !== pdfMakeData.codes.cancel) {
                    let message = '';
                    if (pdfResult.code === pdfMakeData.codes.success) {
                        message = pdfMakeData.message.success;
                    } else if (pdfResult.code === pdfMakeData.codes.ebusy) {
                        message = `Update failed: '${pdfResult.data?.fileName}.pdf' is open. Please close it or rename the file.`;
                    } else if (pdfResult.data?.error) {
                        message = pdfMakeData.message.error;
                        dispatchRaygunError(new Error(pdfResult.data.error), [pdfGenreationError]);
                    }
                    setIdExportPdfGenreate(message)
                }
                setShowLoader(false);
            })
        } else if (channelWindow?.generatePdfResult) {
            window.electron.receive(channelWindow?.generatePdfResult,(pdfResult)=>{
                if(pdfResult.code !== pdfMakeData.codes.cancel){
                    let message = '';
                    if(pdfResult.code === pdfMakeData.codes.success){
                        message = pdfMakeData.message.success;
                    }else if(pdfResult.code === pdfMakeData.codes.ebusy) {
                        message = `Update failed: '${pdfResult.data?.fileName}.pdf' is open. Please close it or rename the file.`;
                    }else if(pdfResult.data?.error){
                        message = pdfMakeData.message.error;
                        dispatchRaygunError(new Error(pdfResult.data.error), [pdfGenreationError]);
                    }
                    setIdExportPdfGenreate(message)
                }
                setShowLoader(false);
              })
        }
    },[])

    return (
        <>
            <button onClick={!disableOnclick ? openPdf : undefined} className='exportBtn' disabled={disabled || !appVersionRef.current}><ExportPdfIcon /> Export PO</button>
            <DialogPopup
                dialogContent={isExportPdfGenerate}
                dialogBtnTitle={commomKeys.successBtnTitle}
                type={commomKeys.actionStatus.success}
                open={!!isExportPdfGenerate}
                onClose={() => setIdExportPdfGenreate(false)}
            />
        </>
    )
}

export default PdfMakePage;