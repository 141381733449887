import clsx from 'clsx';
import {
    useState,
    useEffect,
    useRef
} from 'react';
import styles from './Impersonate.module.scss';
import { useGetAllUsers, useGlobalStore, filterArrray } from '@bryzos/giss-ui-library';
import axios from 'axios';
import DialogPopup from '../DialogPopup/DialogPopup';
import { commomKeys, routes, userRole } from 'src/common';
import { navigatePage } from 'src/renderer/helper';
import { useHeightListener } from 'src/renderer/hooks/useHeightListener';

function Impersonate(props) {
    const ref = useHeightListener();
    const { setShowLoader, userData, setUserData , setHideHeaderState , hideHeaderState} = useGlobalStore();
    const { data: allUsers, isLoading: isGetAllUsersLoading, isError:isGetAllUsersError, error:getAllUsersError } = useGetAllUsers()
    const [userList, setUserList] = useState([]);
    const [filterUserList, setFilterUserList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        if (allUsers && !isGetAllUsersLoading) {
            setUserList(allUsers);
            setFilterUserList(allUsers);
            setShowLoader(false);
        }
        if(isGetAllUsersError){
            if(getAllUsersError.message){
                setErrorMessage(getAllUsersError.message);
            }
        }
    }, [allUsers, isGetAllUsersLoading, isGetAllUsersError])

    const handleSelectedUserLogin = async(impersonateUserData) => {
        try{
            setHideHeaderState(true)
            setShowLoader(true);
            const response = await axios.get(import.meta.env.VITE_API_SERVICE +'/admin/getUserAccessToken?userKey='+impersonateUserData.unique_key,{headers:{
                'email-id': userData?.data?.email_id
            }});
            const token = response.data.data;
            if(typeof response.data.data === 'string'){
                await props.loginImpersonatedUser(token, impersonateUserData);
            }else{
                setErrorMessage(response.data.data.error_message);
                setShowLoader(false);
                setHideHeaderState(false)
            }
        }catch(error) {
            console.log(error);
            if(error?.response){
                let errorMessage = error?.message;
                if(error.response?.status === 400){
                    errorMessage = error.response?.data?.message;
                }
                setErrorMessage(errorMessage);
            }
            setShowLoader(false);
            setHideHeaderState(false)
        }
    }
    const search = (e) => {
        setSearchString(e.target.value);
        if (e.target.value) {
            const _filterArrray = filterArrray(userList, e.target.value, [
                "email_id",
                "first_name",
                "last_name",
                "company_name",
            ]);
            if (_filterArrray?.length) {
                setFilterUserList(_filterArrray);
            } else {
                setFilterUserList([]);
            }
        } else {
            setFilterUserList(userList ? userList : []);
        }
    }

    const handleErrorPopupClose = ()=>{
        setErrorMessage('');
        setShowLoader(true);
        navigatePage(location.pathname, {path: routes.homePage})
    }

    return (
        <>
            <div ref = {ref} className={clsx(styles.impersonateInnerContent, 'bgBlurContent')}>
                <div className={styles.impersonateSearchHeader}>
                     <input type="text" placeholder='Search User' value={searchString} onChange={search} />
                 </div>
                 <div className={styles.userListTable}>
                 <div className={clsx(styles.userListTableContent)}>
                    <div className={styles.tableGrid}>
                        <table>
                            <thead>
                                <tr>
                                    <th><span>First Name</span></th>
                                    <th><span>Last Name</span></th>
                                    <th><span>Email</span></th>
                                </tr>
                            </thead>
                            <tbody>
                            {filterUserList.map((user) => (
                                <tr key={user.id} onClick={()=>{handleSelectedUserLogin(user)}}>
                                    <td>{user.first_name}</td>
                                    <td>{user.last_name}</td>
                                    <td>{user.email_id}</td>
                                </tr>
                             ))}
                            </tbody>
                        </table>
                       
                     
                    </div>
                </div>
                 </div>
          
            </div>
            <DialogPopup
                dialogContent={errorMessage}
                dialogBtnTitle={commomKeys.errorBtnTitle}
                type={commomKeys.actionStatus.error}
                open={!!errorMessage}
                onClose={() => handleErrorPopupClose()}
            />
        </>
    );
}
export default Impersonate;