// @ts-nocheck
import Header from './header';
import { reactQueryKeys, routes } from '../../common';
import { useEventListener } from '@mantine/hooks';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { OTPInputComponent } from '../component/OTPInput';
import Loader from '../Loader/Loader';
import { changePasswordConst, decryptData, emojiRemoverRegex, encryptData, useGlobalStore, getChannelWindow } from '@bryzos/giss-ui-library';
import { useNavigate } from 'react-router';
import useOnSubmitLogin from '../hooks/useOnSubmitLogin';
import useDialogStore from '../component/DialogPopup/DialogStore';
import { ReactComponent as ShowPassIcon } from '../assets/images/show-pass.svg';
import { ReactComponent as HidePassIcon } from '../assets/images/hide-pass.svg';

const Login = ({loginError, setLoginError}) => {
    const channelWindow =  getChannelWindow();
    const navigate = useNavigate();
    const { resetDialogStore } = useDialogStore();
    const setShowLoader = useGlobalStore(state => state.setShowLoader);
    const setIsUserLoggedIn = useGlobalStore(state => state.setIsUserLoggedIn);
    const userCredentials = useGlobalStore(state => state.userCredentials);
    const setUserCredentials = useGlobalStore(state => state.setUserCredentials);
    const decryptionEntity = useGlobalStore(state => state.decryptionEntity);
    const signupUser = useGlobalStore(state => state.signupUser);
    const setSignupUser = useGlobalStore(state => state.setSignupUser);
    const setCurrentAccessToken = useGlobalStore(state => state.setCurrentAccessToken);
    const setStartLoginProcess = useGlobalStore(state => state.setStartLoginProcess);
    const setIsManualLogin = useGlobalStore(state => state.setIsManualLogin);
    const setSystemVersion = useGlobalStore(state => state.setSystemVersion);
    const [resumeApp, setResumeApp] = useState(false);
    const [passwordVisibility, setPasswordVisibility] = useState(true);
    const { register, watch, handleSubmit, setError, setValue, clearErrors, trigger, formState: { errors, isValid, submitCount } } = useForm({
        resolver: yupResolver(
            yup.object({
                email: yup.string().trim().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, {
                    message: 'Please enter valid email'
                }).required('Email is required'),
                password: yup.string().trim()
            }).required()
        ),
        mode: 'onSubmit',
    });
    const onSubmitLogin =  useOnSubmitLogin(setError);
    

    const password = watch('password');

    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const handleFocus = () => {
        setShowPasswordInput(true);
    };

    const handleEmailFocus = () => {
        setShowPasswordInput(false);
    };

    useEffect(() => {
        if (channelWindow?.resumeApp) {
            window.electron.receive(channelWindow.resumeApp, () => {
                setResumeApp(true);
            })
        }
        getUserCred();
        return () => {
            setLoginError();
            resetDialogStore();
        }
    }, [])

    useEffect(() => {
        if (resumeApp) {
            getUserCred();
            setResumeApp(false);
        }
    }, [resumeApp])

    useEffect(() => {
        if (errors.root?.serverError)
            clearErrors('root.serverError')
    }, [watch('email'), watch('password')])

    useEffect(()=>{
        if(userCredentials && decryptionEntity){
            if(userCredentials === changePasswordConst.noUserCredentialFound){
                console.log('old flow')
                setIsUserLoggedIn(true);
                setStartLoginProcess(true);
            }
            else{
                console.log('new flow', userCredentials, decryptionEntity)
                decryptUserCredential(userCredentials, decryptionEntity);
                setUserCredentials(null);
            }
        }
    },[userCredentials, decryptionEntity])


    useEffect(()=>{
        if(signupUser){
            submit(signupUser);
        }
    },[signupUser])

    const decryptUserCredential = async (userCredentials, decryptionEntity) => {
        const data = JSON.parse(await decryptData(userCredentials, decryptionEntity.decryption_key, true));
        submit(data);
    }

    const query = useQueryClient()

    async function submit(data) {
        console.log('submit function')
        setShowLoader(true);
        setStartLoginProcess(true);
        const password = data.password;
        const email = data.email;
        const payload = {email, password};
        onSubmitLogin.mutateAsync(payload);
    }

    const keydownRef = useEventListener('keydown', function (event) {
        if (event.key === 'Enter') {
            handleOnClickLogin()
        }
    }
    );
    const handleOnClickLogin = () => {
        setIsManualLogin(true)
        handleSubmit(submit)()
    } 

    const getUserCred = () => {
        if (channelWindow?.fetchOnRendererMount) {
            const systemData = window.electron.sendSync({ channel: channelWindow.fetchOnRendererMount });
            setUserCredentials(systemData.autoLoginCredential ?? changePasswordConst.noUserCredentialFound);
            setSystemVersion(systemData.systemVersion);
        } else {
            setUserCredentials(changePasswordConst.noUserCredentialFound)
            setShowLoader(false)
        }
    }

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
      };

    return (
        <div>
        <table ref={keydownRef}>
            <tbody>
                <tr className='inputBody'>
                    <td className='enterEmail'>
                        <input type="email" name="email" autoFocus onFocus={handleEmailFocus} {...register("email")}
                            onChange={(e) => {
                                e.target.value = e.target.value.replace(emojiRemoverRegex, '');
                                setLoginError();
                                register("email").onChange(e);
                            }}
                            placeholder='Enter Email' />
                        <p className='errorText'>{errors.email?.message}</p>
                        {(isValid && (password?.length > 1) && !errors.root?.serverError?.message && !loginError) ?
                            <button className={`pressBtn`} onClick={handleOnClickLogin} type="button">Press ENTER to start session</button>
                            :
                            <p className='errorText errorFont'>{loginError ?? errors.root?.serverError?.message}</p>
                        }
                    </td>
                    <td className='enterEmail'>
                    <span className='togglePassWrapper'>
                        <div>
                            <input type={passwordVisibility ? 'password' : 'text'} {...register("password")} placeholder='Enter Password'
                                onChange={(e) => {
                                    setLoginError();
                                    register("password").onChange(e);
                                }} />
                        </div>
                        <button className='showPassBtn' onClick={togglePasswordVisibility}>
                            {passwordVisibility ? <ShowPassIcon /> : <HidePassIcon />}
                        </button>
                    </span>
                        {/* <p className='errorText'>{errors.password?.message}</p> */}
                    </td>
                </tr>
            </tbody>
        </table>
        {(channelWindow?.getLoginCredential) && 
            <div className='forgot-password'>
                <button onClick={()=>navigate(routes.forgotPassword)} className='forgot-password-btn'>Forgot Password?</button>
            </div>
        }
        </div>
    );
};

export default Login;